import { UserHelper } from 'utils/userHelper.util';
import { AuthManagerService } from 'services/auth/authManager.service';

export function useSupplierGuard() {
  if (!AuthManagerService.isAuthProcessActive) {
    redirectFromSupplierPage();
    return;
  }

  if (process.server) {
    return;
  }

  const _watch = watch(
    () => AuthManagerService.isAuthProcessActive,
    () => {
      _watch();
      redirectFromSupplierPage();
    },
  );
}

function redirectFromSupplierPage(): void {
  if (!UserHelper.isSupplier) {
    navigateTo('/');
  }
}
