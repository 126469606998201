<template>
  <div
    class="mm-supplier-layout"
    :class="{
      'mm-supplier-layout-order-split': orderSplit
    }"
  >
    <div class="mm-supplier-wrapper">
      <WarningMessage :variant="EUserType.Supplier" />
      <Header />
      <ClipLoader v-if="isLoading.value" />
      <div
        v-else
        class="mm-supplier-layout__container"
      >
        <slot />
      </div>
    </div>
    <ModalsContainer />
  </div>
</template>

<script lang="ts" setup>
import Header from 'components/layouts/supplier/Header.vue';
import { useSupplierGuard } from 'composables/useSupplierGuard';
import { SupplierHelper } from 'utils/supplierHelper.util';
import { RouterHelper } from 'shared/utils/routerHelper.util';
import ClipLoader from 'shared/components/ClipLoader.vue';
import Loader from 'shared/utils/loaderHelper.util';
import { UserHelper } from 'utils/userHelper.util';
import { useMainTitle } from 'composables/useMainTitle';
import { useServerSsoAuth } from 'composables/useAuthHelper';
import { useIsOrderSplit } from 'composables/useIsOrderSplit';
import { useCheckInternalUser } from 'shared/composables/useCheckInternalUser';
import setAuthCookieHeaders from '../composables/setAuthCookieHeaders';
import { useSupplierActualOfferPolicy } from 'composables/useSupplierActualOfferPolicy';
import { EUserType } from '~/enums/user.enum';

setAuthCookieHeaders();
const isDomainAuth = await useDomainAuth();

await useServerSsoAuth();

useMainTitle();

const isLoading = Loader.getReactiveInstance(true);

const orderSplit = useIsOrderSplit();

await useSetServerUserInfo();

if (!isDomainAuth && !UserHelper.isSupplier){
  await navigateTo('/');
}

function redirectToInitTab(): void {
  RouterHelper.isPathEndWith('supplier/orders/') && navigateTo('/supplier/orders/?orderType=all');
}

useSetClientUserInfo();
useCheckInternalUser();
useSupplierActualOfferPolicy();

onMounted(async () => {
  try {
    await SupplierHelper.updateQualificationStatus();
  } finally {
    isLoading.deactivate();
    useSupplierGuard();
    redirectToInitTab();
  }
});
</script>

<style lang="scss" scoped>
.mm-supplier-layout {
  height: 100%;
  display: flex;
  flex-direction: column;

  &__container {
    width: 100%;
    margin: 0 auto;
    position: relative;
    padding: 0 8px;
    height: 100%;

    & > .mm-clip-loader__clip {
      position: fixed;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .mm-supplier-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;

    .mm-supplier-layout__container {
      height: unset !important;
      flex: 1;
    }
  }
}

@media screen and (max-width: 1279px) {
  .mm-supplier-layout__container {
    padding: 0 4px;
  }
}

@media screen and (max-width: 1279px) and (max-height: 800px){
  .mm-supplier-layout__container {
    min-height: calc(100vh + 67px);
  }
}
</style>
