<template>
  <Modal
    title-text="Обновление оферты"
    ok-button-text="Посмотреть оферту"
    hide-cancel-button
    class="mm-supplier-accept-offer-policy-modal"
    z-index="100000 !important"
    classes="modal fixed-bottom-modal"
    :disabled-close-button="disabledButton"
    :disabled-ok-button="disabledButton"
    @close="$emit('close')"
    @ok="onOpenOfferPolicy"
  >
    <div class="mm-supplier-accept-offer-policy-modal__inner">
      C {{ DateFormat.default(offerPolicy.offerDate as string) }} вступает в действие новая редакция оферты, которая
      <span
        class="mm-link"
        :class="{
          'mm-link--disabled': disabledButton
        }"
        @click="onOpenOfferPolicy"
      >доступна по ссылке</span> и в разделе документы
    </div>
  </Modal>
</template>

<script lang="ts" setup>
import Modal from 'shared/components/modals/Modal.vue';
import { ISupplierOfferPolicy } from 'models/supplier/supplierOfferPolicy.model';
import Loader from 'shared/utils/loaderHelper.util';
import { DateFormat } from 'shared/utils/dateFormat.util';
import FilesService from 'shared/services/api/files.service';
import { clientSentry } from 'shared/utils/sentry/clientSentry.util';
import Notificator from 'shared/services/notificator.service';

const props = defineProps<{
  offerPolicy: ISupplierOfferPolicy;
  loadingOfferPolicies: Loader;
  loadingApproveOfferPolicy: Loader;
}>();

defineEmits<{
  (e: 'close'): void;
}>();

const filesService = inject<FilesService>(FilesService.getServiceName());

const loadingDownload = Loader.getReactiveInstance();
const offerPolicyUrl = ref<string>('');

const disabledButton = computed(
  () => props.loadingOfferPolicies.value
    || loadingDownload.value
    || props.loadingApproveOfferPolicy.value,
);

async function onOpenOfferPolicy(): Promise<void> {
  if (!offerPolicyUrl.value) {
    await downloadOfferPolicy();
  }

  if (offerPolicyUrl.value) {
    window.open(offerPolicyUrl.value);
  }
}

async function downloadOfferPolicy(): Promise<void> {
  if (loadingDownload.value) {
    return;
  }

  loadingDownload.activate();

  const fileId = props.offerPolicy.fileId;
  try {
    const response = await filesService?.downloadFile(
      fileId,
      `/api/v2/offer_policy/${fileId}/`,
    );

    if (!response?.data) {
      throw Error();
    }

    offerPolicyUrl.value = URL.createObjectURL(response.data);
  } catch (error) {
    clientSentry.captureException(error);
    Notificator.showDetachedNotification('Ошибка при скачивании оферты');
  } finally {
    loadingDownload.deactivate();
  }
}
</script>

<style lang="scss" scoped>
@import 'styles/base/common/variables';

.mm-supplier-accept-offer-policy-modal {
  &__inner {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    color: $text-dark-green;

    .mm-link {
      &--disabled {
        color: $text-disabled;
        cursor: wait;
      }
    }
  }
}
</style>
