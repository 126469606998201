import ModalManager from 'shared/services/modalManager.service';
import Notificator from 'shared/services/notificator.service';
import Loader from 'shared/utils/loaderHelper.util';
import { clientSentry } from 'shared/utils/sentry/clientSentry.util';
import { ISupplierOfferPolicy } from 'models/supplier/supplierOfferPolicy.model';
import { SupplierApiService } from 'services/api/supplierApi.service';
import SupplierAcceptOfferPolicyModal from 'components/supplier/SupplierAcceptOfferPolicyModal.vue';
import { useUserStore } from 'store/user.store';

export function useSupplierActualOfferPolicy(): void {
  const modalManager = inject<ModalManager>(ModalManager.getServiceName());
  const loadingOfferPolicies = Loader.getReactiveInstance();
  const loadingApproveOfferPolicy = Loader.getReactiveInstance();
  const userOfferPolicies = ref<Array<ISupplierOfferPolicy>>([]);
  const modalName = 'supplierAcceptOfferPolicyModal';
  const userStore = useUserStore();

  async function loadUserOfferPolicies(): Promise<void> {
    if (loadingOfferPolicies.value
      || !userStore.isSupplierAdmin
      || userStore.mpAdminInfoEmail
    ) {
      return;
    }

    loadingOfferPolicies.activate();

    try {
      userOfferPolicies.value = await SupplierApiService.getOfferPoliciesForCurrentUser();
      checkUnapprovedOffers();
    } catch (error) {
      clientSentry.captureException(error);
      Notificator.showDetachedNotification('Ошибка загрузки актуальных оферт');
    } finally {
      loadingOfferPolicies.deactivate();
    }
  }

  async function approveOfferPolicy(offerPolicy: ISupplierOfferPolicy): Promise<void> {
    loadingApproveOfferPolicy.activate();

    try {
      await SupplierApiService.approveOfferPolicy(offerPolicy.id);
    } catch (error) {
      clientSentry.captureException(error);
      Notificator.showDetachedNotification('Ошибка принятия оферты');
    } finally {
      loadingApproveOfferPolicy.deactivate();
    }
  }

  function checkUnapprovedOffers(): void {
    if (!userOfferPolicies.value?.length) {
      modalManager?.hide(modalName);
      return;
    }

    modalManager?.hide(modalName);

    const offerPolicy = userOfferPolicies.value[0];

    modalManager?.show({
      component: SupplierAcceptOfferPolicyModal,
      bind: {
        name: modalName,
        offerPolicy,
        loadingOfferPolicies,
        loadingApproveOfferPolicy,
      },
      on: {
        async close() {
          await approveOfferPolicy(offerPolicy);
          await loadUserOfferPolicies();
        },
      },
    });
  }

  onMounted(async () => await loadUserOfferPolicies());
}
